import React, { useState, useEffect } from 'react';
import './Reading.css';
import CardsDisplay from './CardsDisplay'

const spreads = [
  { name: 'Yes/No', description: 'A single card reading - yes or no answer to your question', cardTitles: ['Answer'] },
  { name: 'Past Present & Future', description: 'A simple three card reading', cardTitles: ['Past', 'Present', 'Future'] },
  { name: 'Love', description: 'Explore the dynamics of romantic relationships', cardTitles: ['Your Feelings', 'Their Feelings', 'Current Relationship State', 'Potential Outcome'] },
  { name: 'Career', description: 'Four cards offer guidance related to career path', cardTitles: ['Current Situation', 'Potential Challenges', 'What to Avoid', 'What to Embrace', 'Potential Outcome'] },
  { name: 'Spiritual Path', description: 'The simplified 5 card spiritual path  - Helps to understand spiritual direction and challenges', cardTitles: ['Your Current Path', 'Your Potential', 'What is Challenging You', 'How to Overcome', 'Your Spiritual Outcome'] },
  { name: 'Mind, Body, Spirit', description: 'Three cards - Insight into overall well-being', cardTitles: ['Mind', 'Body', 'Spirit'] },
  { name: 'Celtic Cross', description: 'A 10 card reading - Detailed insight covering various aspects of life', cardTitles: ['Present', 'Challenge', 'Past', 'Future', 'Best Outcome', 'Immediate Future', 'Factors or Inner Feelings', 'External Influences', 'Hopes and Fears', 'Final Outcome'] },
];

const Reading = () => {
  // Array containing details of each spread including name and specific titles for each card
  

  // Fetched card data from the API
  const [deckData, setDeckData] = useState(null);
  // State for displaying fetch errors
  const [fetchErrorStatus, setFetchError] = useState(null);
  // Currently selected spread
  const [selectedSpreadIndex, setSelectedSpreadIndex] = useState(1);

  const BASEURL = "https://tarot-card-api-775f10295f96.herokuapp.com";

  // useEffect hook triggers fetching of cards when selectedSpreadIndex changes
  useEffect(() => {
    const fetchDeckData = async () => {
      try {
        const numCards = spreads[selectedSpreadIndex].cardTitles.length;
        const response = await fetch(`${BASEURL}/randomCards/?n=${numCards}`);
        if (!response.ok) {
          setFetchError(response.message);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Card Data:", data);
        setDeckData(data);
        setFetchError(null);
      } catch (error) {
        console.error('Error fetching API health status:', error);
        setFetchError(error.message);
      }
    };
    fetchDeckData();
  }, [selectedSpreadIndex]);

const handleSpreadChange = (index) => {
  setSelectedSpreadIndex(index);
};

  // Component rendering
  return (
    <div className="reading-container">
      <div className="spread-selection" id="reading-section">
        <h3 className="spread-selection__title"><i>Your Spread</i></h3>
        <div className="spread-buttons">
          {spreads.map((spread, index) => (
            <button
              key={index}
              className={`spread-button ${index === selectedSpreadIndex ? 'selected' : ''}`}
              onClick={() => handleSpreadChange(index)}
              title={spread.description} // Tooltip added here
            >
              {spread.name}
            </button>
          ))}
        </div>
      </div>
      <CardsDisplay deckData ={deckData} selectedSpreadIndex={selectedSpreadIndex} spreads={spreads} fetchErrorStatus={fetchErrorStatus}/>
    </div>
  );
};

export default Reading;