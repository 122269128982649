import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import './MinimizableComponent.css';

const MinimizableComponent = ({ children, title, startMinimized }) => {
  const [isMinimized, setIsMinimized] = useState(startMinimized || false);

  const handleMinimizeToggle = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`container`}>
      <div className='title' onClick={handleMinimizeToggle}>
        <div className='expansion-icon-div'>
          <FontAwesomeIcon  
            icon={isMinimized ? faExpandAlt : faMinusCircle}
            className ='minimize-icon'>
          </FontAwesomeIcon>
        </div>
        <p>{title}</p>
      </div>
      {!isMinimized && children}
    </div>
  );
};

export default MinimizableComponent;