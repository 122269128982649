import React, {  } from 'react';
import './Footer.css';  // Assuming you'll create a separate CSS file for footer styling
import MinimizableComponent from '../wrappers/MinimizableComponent';

const Footer = () => {

  
  return (
    <div className="footer">
      <p></p>
      <p>Clear Sky Tarot Inc.</p>
      <MinimizableComponent title="Feedback" startMinimized={true}>
        <div className='iframeContainer'>
        <iframe title="feedback survey"src="https://docs.google.com/forms/d/e/1FAIpQLSeNo7yQZnNlZuwXyc6ZlRiaSpAH86NerJN_T-RvHK_qTT20PQ/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </MinimizableComponent>
      <p>Ossining, NY.</p>
    </div>
  );
};


export default Footer;