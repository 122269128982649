import React from 'react';
import Reading from './reading/Reading';
import LandingPage from './landing/LandingPage';
import Footer from './footer/Footer';

const App = () => {
  return (
    <div className='FilmGrain'>
      <LandingPage/>
      <Reading />
      <Footer />
    </div>
  );
};

export default App;