import React, { useState } from 'react';

const Card = ({ cardObj}) => {
  const { name, imgur, keywords, reversed, reversedkeywords, element, backimgur } = cardObj;
  const { divinatorymeaning, divinatorymeaningreversed } = cardObj.detailed_information;

  const [flipped, setFlipped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setFlipped(!flipped);
  };

  const getKeywords = () => {
    return reversed ? reversedkeywords : keywords;
  };

  const getDivinatoryMeaning = () => {
    return reversed ? divinatorymeaningreversed : divinatorymeaning;
  };

  const imageStyle = {
    maxWidth: '100%',
    height: 'auto',
    transform: `rotateY(${flipped ? '0' : '180'}deg) `, // Adjusted transform property
    transition: 'transform 0.3s ease',
  };


  const textStyle = {
    visibility: flipped ? 'visible' : 'hidden',
    opacity: flipped ? 1 : 0,
    transition: 'visibility 0s, opacity 0.5s linear',
  };

  return (
    <div
      style={{
        // border: '1px solid #ccc',
        borderRadius: '8px',
        maxWidth: '300px',
        textAlign: 'center',
        cursor: 'pointer', // Add cursor pointer to indicate it's clickable
        transform: `translateY(${isHovered ? '-2px' : '0'})`, transition: 'transform 0.1s ease' 
      }}
      onClick={handleClick} // Handle click event to flip the card
      onMouseEnter={() => setIsHovered(true)} // Handle mouse enter event
      onMouseLeave={() => setIsHovered(false)} // Handle mouse leave event
    >
      <div style = {reversed? {transform: `rotateX(${180}deg)`}:{}}>
      <img src={flipped ? imgur : backimgur} alt={name} style={imageStyle} />
      </div>
      <h2 style={textStyle}>{reversed? <i>Reversed </i>:" "}{name}</h2>
      <p style={textStyle}>Element: {element} </p>
      <p style={textStyle}>{getKeywords()}</p>
      <p style={textStyle}>{getDivinatoryMeaning()}</p>
    </div>
  );
};

export default Card;