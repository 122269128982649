import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="landing-page__sun-pattern"></div>
      <div className="landing-page__content">
        <a href="/" className="landing-page__home-link">
          <img src="/images/clearsky4.png" alt="Clear Sky" />
          <h3>Clear Sky Tarot</h3>
        </a>
        <div className="landing-page_body">
        <h1 className="landing-page__title">Welcome to <i>Your Tarot Reading</i></h1>
        <p className="landing-page__description">
          Discover the secrets of the ancient art of Tarot. Unlock
          insights into your past, present, and future✨
        </p>

        </div>
        <a href="#reading-section"> {/* Use href to link to the ID */}
          <button className="landing-page__button">🔮 Start Now</button>
        </a>

      </div>
    </div>
  );
};

export default LandingPage;