import React, {  } from 'react';
import Card from "./Card"
const CardsDisplay = ({ spreads, selectedSpreadIndex, deckData , fetchErrorStatus}) =>{
    return(
        <div className="cards-display">
            {deckData && deckData.map((cardObj, index) => (
              <div key={`${index}-${selectedSpreadIndex}`}>
                <h2>{spreads[selectedSpreadIndex].cardTitles[index]}</h2>
                <Card cardObj={cardObj} />
              </div>
            ))}
            {fetchErrorStatus && <div>Error: {fetchErrorStatus}</div>}
          </div>
        );

}
export default CardsDisplay;
